import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './OurOfficeLocation.css';

function OurOfficeLocation() {
  return (
    <Container
      fluid="xl"
      className="ourOfficeLocation contactusform py-3 mb-lg-4"
    >
      <Container className="contactus my-blue-text mx-auto">
        <h3 className="text-capitalize text-center mb-md-4">
          Our Office Location
        </h3>

        <Row xs={12} className="my-3 fadeInUp">
          <Col xs={12} md={6}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15555.63900264744!2d77.53820819902292!3d12.913521798342435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3fd31c668c79%3A0x3ad68abca9ed399!2sChikkalasandra%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1726425208124!5m2!1sen!2sin"
              style={{
                border: 0,
                width: '100%',
                height: '100%',
                backgroundColor: '#cccccc54',
              }}
              className="rounded"
              loading="lazy"
              title="Chikkalsandra Bangalore"
              aria-label="Chikkalsandra Bangalore"
            ></iframe>
          </Col>

          <Col xs={12} md={6}>
            <h5 className="mt-5 mt-md-0">Headquarters:</h5>
            <p className="mb-1 pgraph">
              <i className="fa fa-map-marker-alt me-3"></i>Magan Silver Hill,
              Chikkalasandra, Bangalore
            </p>
            <p className="mb-1 pgraph">
              <i className="fa fa-phone-alt me-3"></i>+91-6361715233
            </p>
            <p className="mb-1 pgraph">
              <i className="fa fa-envelope me-3"></i>icrewinbox@gmail.com
            </p>
            <h5 className="mt-4">Branch:</h5>
            <p className="mb-2 pgraph">
              <i className="fa fa-map-marker-alt me-3"></i>Godrej Elements,
              Hinjewadi Phase 1, Pune
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default OurOfficeLocation;
