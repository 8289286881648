import ContactUsForm from '../../component/contact/ContactUsForm/ContactUsForm';
import OurOfficeLocation from '../../component/contact/OurOfficeLocation/OurOfficeLocation';

function Contact() {
  return (
    <>
      <ContactUsForm /> {/* animation */}
      <OurOfficeLocation /> {/* animation */}
    </>
  );
}

export default Contact;
