const SocialLink = [
  {
    color: '#316FF6',
    icon: 'fa-facebook-f',
    url: 'https://www.facebook.com/iCrewFacebookPage',
  },
  {
    color: '#0077B5',
    icon: 'fa-linkedin-in',
    url: 'https://www.linkedin.com/company/icrew-financial-services/',
  },
  {
    color: '#FF0000',
    icon: 'fa-youtube',
    url: 'https://www.youtube.com/@iCreW_financial_services',
  },
  {
    color: '#E1306C',
    icon: 'fa-instagram',
    url: 'https://www.instagram.com/icrewonline/?hl=en',
  },
];

export default SocialLink;
