import CountUp from 'react-countup';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Facts.css';
import SectionHeading from '../../../ui/SectionHeading/SectionHeading';

function Facts() {
  return (
    <Container fluid className="facts mt-5 py-5">
      <Container fluid="xl" className="mt-2 my-blue-text">
        <SectionHeading type="h2">Insights</SectionHeading>

        <Row className="g-5 mt-2 my-blue-text">
          <Col
            sm={6}
            lg={3}
            className="text-center wow fadeIn asdasd"
            data-wow-delay="0.1s"
          >
            <i className="fa fa-users fa-3x mb-3"></i>
            <div className="display-4" data-toggle="counter-up">
              <CountUp
                delay={2}
                end={93}
                duration={2}
                redraw={false}
                enableScrollSpy={true}
                scrollSpyOnce={true}
              />
            </div>
            <span className="fs-5">Happy Clients</span>
            <hr className="bg-white w-25 mx-auto mb-0" />
          </Col>
          <Col
            sm={6}
            lg={3}
            className="text-center wow fadeIn"
            data-wow-delay="0.3s"
          >
            <i className="fa fa-house-chimney-user fa-3x mb-3"></i>
            {/* fa-medal */}
            <div className="display-4" data-toggle="counter-up">
              <CountUp
                delay={2}
                end={102}
                duration={2}
                redraw={false}
                enableScrollSpy={true}
                scrollSpyOnce={true}
              />
            </div>
            <span className="fs-5">Projects Completed</span>
            <hr className="bg-white w-25 mx-auto mb-0" />
          </Col>
          <Col
            sm={6}
            lg={3}
            className="text-center wow fadeIn"
            data-wow-delay="0.5s"
          >
            <i className="fa fa-award fa-3x mb-3"></i>
            <div className="display-4" data-toggle="counter-up">
              <CountUp
                delay={2}
                end={12}
                duration={2}
                redraw={false}
                enableScrollSpy={true}
                scrollSpyOnce={true}
              />
            </div>
            <span className="fs-5">Awards</span>
            <hr className="bg-white w-25 mx-auto mb-0" />
          </Col>
          <Col
            sm={6}
            lg={3}
            className="text-center wow fadeIn"
            data-wow-delay="0.7s"
          >
            <i className="fa fa-user-group fa-3x mb-3"></i>
            {/* fa-users-cog */}
            <div className="display-4" data-toggle="counter-up">
              <CountUp
                delay={2}
                end={15}
                duration={2}
                redraw={false}
                enableScrollSpy={true}
                scrollSpyOnce={true}
              />
            </div>
            <span className="fs-5">Dedicated Team</span>
            <hr className="bg-white w-25 mx-auto mb-0" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Facts;
