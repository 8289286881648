import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';

import SocialLink from '../../data/SocialLink';

import './Footer.css';

function Footer() {
  const links = [
    { lable: 'About Us', href: '/about' },
    { lable: 'Free Appointment', href: '/appointment' },
    { lable: 'Terms & Condition', href: '' },
    { lable: 'Support', href: '' },
    { lable: 'Contact Us', href: '/contact' },
  ];
  return (
    <>
      <Container
        fluid
        className="footer text-light wow fadeIn"
        data-wow-delay="0.1s"
      >
        <Container fluid="xl" className="py-5">
          <Row>
            <Col lg={6} sm={12}>
              <Button as={Link} to="/" className="footer-logo p-0 mb-3">
                {/* <h5 className="mb-3">iCreW, India Creates Wealth</h5> */}
                <span
                  className="image bg-light rounded-circle me-3"
                  aria-hidden="true"
                ></span>
                <span className="head">
                  <span className="iLetter">i</span>CreW
                </span>
                <span className="quote">India Creates Wealth</span>
              </Button>

              <p className="paragraph">
                Our vision is to be the premier destination for individuals
                seeking comprehensive financial solutions and expert guidance.
                We envision a future where financial planning is accessible,
                transparent and tailored to each individuals.
              </p>
              {/* <p className="paragraph">
                Our ultimate goal is to empower our clients to take control of
                their financial futures and unlock their full potential for
                wealth accumulation, preservation, and growth.
              </p> */}
            </Col>
            <Col lg={3} sm={6} className="secondBox">
              <h4 className="heading mt-2 mb-3">Our Office</h4>
              <p className="mb-1">
                <i className="fa fa-map-marker-alt me-3"></i>Magan Silver Hill,
                Chikkalasandra, Bangalore
              </p>
              <p className="mb-1">
                <i className="fa fa-phone-alt me-3"></i>+91-6361715233
              </p>
              <p className="mb-1">
                <i className="fa fa-envelope me-3"></i>icrewinbox@gmail.com
              </p>
            </Col>
            <Col lg={3} sm={6} className="thirdBox">
              <h4 className="heading mt-2 mb-3">Quick Links</h4>
              <div>
                {links.map((lin, i) => (
                  <Button
                    variant="link"
                    as={Link}
                    to={lin.href}
                    className="quicklink text-light text-decoration-none text-decoration-hover p-0"
                    key={`quicklink${i}`}
                  >
                    {lin.lable}
                  </Button>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container
        fluid
        className="footer-black py-3 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <Container fluid="xl">
          <Row>
            <Col
              md={6}
              className="text-center text-md-start text-light mb-3 mb-md-0 copyright"
            >
              &copy; 2024 iCreW, India Creates Wealth, All Right Reserved.
            </Col>
            <Col md={6} className="text-center text-md-end">
              {SocialLink.map((link, i) => (
                <Button
                  target="_blank"
                  variant="outline-light"
                  className="social-icon rounded-circle me-2 p-1 pb-0 mx-0"
                  href={link.url}
                  key={`footerSocial${i}`}
                >
                  <i className={`fab ${link.icon}`}></i>
                </Button>
              ))}
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Footer;
