import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function TopHeader() {
  function renderIcon(whichIcon) {
    return <i className={`fa ${whichIcon} me-2`}></i>;
  }

  return (
    <Row
      className={'gx-0 align-items-center d-none d-lg-flex text-light'}
      style={{
        height: '45px',
        borderBottom: '1px solid rgba(53, 94, 252, 0.07)',
        backgroundColor: 'var(--top-bg)',
      }}
    >
      <Col className="col-lg-6 px-5 text-start">
        <small>
          {renderIcon('fa-map-marker-alt')} Magan Silver Hill, Chikkalasandra,
          Bangalore
        </small>
        <small className="ms-4">
          {renderIcon('fa-clock')} 9.00 am - 9.00 pm
        </small>
      </Col>
      <Col className="col-lg-6 px-5 text-end">
        <small>{renderIcon('fa-envelope')} icrewinbox@gmail.com</small>
        <small className="ms-4">{renderIcon('fa-phone')} +91-6361715233</small>
      </Col>
    </Row>
  );
}

export default TopHeader;
