import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

import useBreakpoint from '../../../hooks/use-breakpoint';

import './VideoBanner.css';

function VideoBanner({ ...props }) {
  const size = useBreakpoint();

  let displayH = 'display-6';

  if (size === 'lg' || size === 'xl' || size === 'xxl') {
    displayH = 'display-5';
  }

  return (
    <Container fluid className="p-0 mb-5 videoBannerCont" {...props}>
      <div id="video-bg">
        <video loop autoPlay muted>
          <source src="assets/video_banner.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div id="video-desc">
        <Container fluid="xl">
          <div className="desc py-sm-5 py-4">
            {/* ps-sm-5 ps-2 */}
            <Col lg={5} md={5} sm={5}>
              <h1 className={`${displayH} mb-sm-5 mb-4`}>
                Our Financial Advising team can help optimize your financial
                life
              </h1>
              <Button
                className="py-2 px-4 fs-5 my-blue-btn"
                as={Link}
                to="/appointment"
              >
                Book An Appointment
              </Button>
            </Col>
          </div>
        </Container>
      </div>
    </Container>
  );
}

export default VideoBanner;
