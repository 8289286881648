import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function NoPage() {
  return (
    <Container fluid="xl" className="py-5 wow fadeInUp" data-wow-delay="0.1s">
      <Container className="text-center">
        <Row className="justify-content-center my-blue-text">
          <Col lg={6}>
            <i className="fa-solid fa-bomb" style={{ fontSize: '8rem' }}></i>
            <h1 className="display-2 mt-4">404</h1>
            <h1 className="mb-4">Page Not Found</h1>
            <p className="mb-4">
              We're sorry, the page you requested could not be found.
            </p>

            <Button
              className="py-sm-3 px-sm-5 py-2 px-4 my-blue-btn"
              as={Link}
              to="/"
            >
              Go Back To Home
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default NoPage;
