import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import './QualifiedTeam.css';

function QualifiedTeam() {
  const data = [
    {
      icon: 'fa-user-graduate' /* fa-user-tie fa-users-rectangle */,
      title: 'Qualified Team',
    },
    {
      icon: 'fa-user-lock' /* fa-regular fa-user */,
      title: 'Individual Approach',
    },
    {
      icon: 'fa-chart-line' /* fa-child-reaching */,
      title: '100% Success',
    },
    {
      icon: 'fa-regular fa-thumbs-up',
      title: '100% Satisfaction',
    },
  ];

  return (
    <Container fluid="xl" className="qualified my-4">
      <Row xs={2} md={4} className="g-4 align-items-stretch fadeInUp">
        {data.map((_, idx) => (
          <Col key={idx}>
            <Card className="text-center my-blue-text hoverEffect">
              <Card.Body className="d-flex flex-column align-items-center">
                <Card.Text className="my-3">
                  <i className={`fa ${_.icon} fa-4x mb-3`}></i>
                </Card.Text>
                <Card.Title className="text-capitalize text-center mx-auto">
                  {_.title}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default QualifiedTeam;
