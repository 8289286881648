import FinancialJourney from '../../component/about/FinancialJourney/FinancialJourney';
import OurPeople from '../../component/about/OurPeople/OurPeople';
import StepProcess from '../../component/about/StepProcess/StepProcess';
import WhyiCreW from '../../component/about/WhyiCreW/WhyiCreW';

function About() {
  return (
    <>
      <WhyiCreW /> {/* animation */}
      <StepProcess /> {/* animation */}
      <OurPeople /> {/* animation */}
      <FinancialJourney />
    </>
  );
}

export default About;
