import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Layout from '../../component/Layout/Layout';
import Home from '../Home/Home';
import Contact from '../Contact/Contact';
import About from '../About/About';
import NoPage from '../NoPage/NoPage';
import ScheduleAppointment from '../ScheduleAppointment/ScheduleAppointment';

const router = createBrowserRouter([
  {
    element: <Layout />,
    /* loader: rootLoader, */
    children: [
      {
        path: '/',
        element: <Home />,
        /* loader: teamLoader, */
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/contact',
        element: <Contact />,
      },
      {
        path: '/appointment',
        element: <ScheduleAppointment />,
      },
      {
        path: '*',
        element: <NoPage />,
      },
    ],
  },
]);

function App() {
  /* const [data, setData] = React.useState(null);

  React.useEffect(() => {
    fetch("/api")
      .then((res) => res.json())
      .then((data) => setData(data.message));
  }, []);

  <div className="App">
      <header className="App-header">
        <p>{!data ? "Loading..." : data}</p>
      </header>
    </div> */

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
