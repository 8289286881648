import { useEffect, useState } from 'react';
import axios from 'axios';

import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Alert from 'react-bootstrap/Alert';

import './AppointmentForm.css';

const animatedComponents = makeAnimated();
const data = [
  { value: 'Car', label: 'Car' },
  { value: 'House', label: 'House' },
  { value: 'Foreign Trip', label: 'Foreign Trip' },
  { value: 'Kids education', label: 'Kids education' },
  {
    value: 'none',
    label: 'Type Your Other Goal ...',
    isDisabled: true,
  },
];

function AppointmentForm() {
  const [validated, setValidated] = useState(false);
  const [response, setResponse] = useState();
  const [goals, setSelectedOption] = useState([]);

  useEffect(() => {
    const goalEle = document.querySelector('.react-select-container');
    if (goalEle) {
      goalEle.classList.remove('empt');
      goalEle.classList.remove('dtfull');

      if (!goals.length) {
        goalEle.classList.add('empt');
      }
      if (goals.length) {
        goalEle.classList.add('dtfull');
      }
    }
  }, [goals]);

  const handleSubmit = async (event) => {
    setValidated(true);
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() && goals.length) {
      var formData = Object.fromEntries(new FormData(form));
      const finalGoals = goals.map((g) => g.value);
      formData.goals = finalGoals;

      try {
        const resp = await axios.post('/api/appointment/submit', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setResponse(resp);
      } catch (err) {
        setResponse(err);
      }
    }
  };

  const colourStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      borderColor: isFocused ? '#86b7fe' : undefined,
      borderWidth: isFocused ? 1 : undefined,
      /* outline: isFocused ? 0 : undefined, */
      boxShadow: isFocused ? '0 0 0 .25rem rgba(13, 110, 253, .25)' : undefined,
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: 'var(--tertiary)',
    }),
  };

  return (
    <Container fluid="xl" className="scheduleAppoform py-5 shadow">
      <Container className="scheduleappo mx-auto my-blue-text">
        <h4>Schedule a free consultation at your preferred time</h4>

        <p className="mb-4 fs-6">
          Please fill these details corrrectly so that Our team can create a
          Financial Plan for you.
        </p>

        <Row className="rounded rowform">
          <Col
            className="wow fadeInUp"
            data-wow-delay="0.1s"
            style={{
              marginTop: 'calc(var(--bs-gutter-x)* .5)',
              marginBottom: 'calc(var(--bs-gutter-x)* .5)',
            }}
          >
            <Form validated={validated} onSubmit={handleSubmit} noValidate>
              {response && (
                <>
                  <Alert
                    key={`${response.status === 200 ? 'success' : 'danger'}`}
                    variant={`${
                      response.status === 200 ? 'success' : 'danger'
                    }`}
                  >
                    {response.data.message}
                  </Alert>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </>
              )}
              {(response === undefined || response.status !== 200) && (
                <Row className="g-3">
                  <Col sm={12} md={6}>
                    <FloatingLabel controlId="formName" label="Name">
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        required
                      />
                    </FloatingLabel>
                  </Col>
                  <Col sm={12} md={6}>
                    <FloatingLabel controlId="formEmail" label="Email">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        required
                      />
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} md={6}>
                    <FloatingLabel controlId="formPhone" label="Mobile">
                      <Form.Control
                        type="tel"
                        name="phone"
                        placeholder="Enter your mobile"
                        required
                      />
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} md={6}>
                    <FloatingLabel controlId="formAge" label="Date of Birth">
                      <Form.Control
                        type="date"
                        name="dob"
                        min="0"
                        placeholder="Enter your date of birth"
                        required
                      />
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} md={6}>
                    <FloatingLabel
                      controlId="formMonthlyIncome"
                      label="Monthly Income: in-hand salary"
                    >
                      <Form.Control
                        type="number"
                        min="0"
                        name="monthlyincome"
                        placeholder="In-hand monthly salary"
                        required
                      />
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} md={6}>
                    <FloatingLabel
                      controlId="formTotalExpenses"
                      label="Total Monthly Expenses"
                    >
                      <Form.Control
                        type="number"
                        min="0"
                        name="totalexpenses"
                        placeholder="Total Expenses per month"
                        required
                      />
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} md={6}>
                    <FloatingLabel
                      controlId="formTotalLoan"
                      label="Total Loan Details"
                    >
                      <Form.Control
                        type="number"
                        min="0"
                        name="totalloan"
                        placeholder="Total Loan Details"
                        required
                      />
                    </FloatingLabel>
                    <p className="hintlabel mb-0">
                      (Total loan EMI's paid per month)
                    </p>
                  </Col>

                  <Col sm={12} md={6}>
                    <FloatingLabel
                      controlId="formTotalOther"
                      label="Total Other Investment"
                    >
                      <Form.Control
                        type="number"
                        min="0"
                        name="totalotherinvestment"
                        placeholder="Total Other Investment"
                        required
                      />
                    </FloatingLabel>
                    <p className="hintlabel mb-0">
                      (Stocks, MF, Savings etc including PF account)
                    </p>
                  </Col>

                  <Col sm={12} md={6}>
                    <CreatableSelect
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="goals"
                      placeholder="Select Your Goals"
                      /* noOptionsMessage={({ inputValue }) => 'Enter your goal'} */
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      /* defaultValue={defaultValues} */
                      options={data}
                      styles={colourStyles}
                      onChange={(val) => setSelectedOption(val)}
                      isMulti
                      isSearchable
                    />
                  </Col>

                  <Col sm={12} md={6}>
                    <FloatingLabel
                      controlId="formRetirement"
                      label="Retirement Corpus"
                    >
                      <Form.Control
                        type="number"
                        min="0"
                        name="retirement"
                        placeholder="Retirement Corpus"
                        required
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12} className="mt-md-4 mb-md-3">
                    <FloatingLabel
                      controlId="formMessage"
                      label="Questions / Suggestion / Request"
                    >
                      <Form.Control
                        as="textarea"
                        name="message"
                        placeholder="Questions / Suggestion / Request"
                        style={{ height: '100px' }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col sm={12} md={3}>
                    <FloatingLabel
                      controlId="formPreferredDate"
                      label="Preferred Date"
                    >
                      <Form.Control
                        type="date"
                        name="preferreddate"
                        placeholder="Preferred Date"
                        required
                      />
                    </FloatingLabel>
                  </Col>
                  <Col sm={12} md={3}>
                    <FloatingLabel controlId="formPreferredTime" label="Time">
                      <Form.Control
                        type="time"
                        name="preferredtime"
                        placeholder="Time"
                      />
                    </FloatingLabel>
                  </Col>
                  <Col sm={12}>
                    <Button
                      className="py-2 px-3 mt-2 my-blue-btn"
                      type="submit"
                    >
                      Submit <i className="fa-fw fa-regular fa-paper-plane"></i>
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default AppointmentForm;
