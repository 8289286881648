import Container from 'react-bootstrap/Container';

import Carousel, { consts } from '@itseasy21/react-elastic-carousel';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import SectionHeading from '../../../ui/SectionHeading/SectionHeading';

import './Testimonial.css';
import Feedbacks from '../../../data/Feedbacks';

function Testimonial() {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 4 },
  ];

  function myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <i className="fa-solid fa-arrow-left"></i>
      ) : (
        <i className="fa-solid fa-arrow-right"></i>
      );
    return (
      <Button
        type="button"
        className="fs-6 my-blue-btn arrow"
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </Button>
    );
  }

  /* https://rubyyagi.com/how-to-truncate-long-text-and-show-read-more-less-button/
  https://github.com/alexandersmanning/read-more-react
  */

  return (
    <Container fluid="xl" className="testimonial my-blue-text my-3 py-5">
      <Container>
        <div
          className="text-center mx-auto wow fadeInUp mb-4"
          data-wow-delay="0.1s"
          style={{ maxWidth: '600px' }}
        >
          <p className="fw-semi-bold mb-1">Testimonial</p>
          <SectionHeading type="h2">What Our Clients Say!</SectionHeading>
        </div>

        <Carousel
          breakPoints={breakPoints}
          itemPadding={[0, 8]}
          renderArrow={myArrow}
        >
          {Feedbacks.map((item) => (
            <div key={`testimonial${item.id}`}>
              <Card className="text-center">
                <Image
                  className="shadow rounded-circle mx-auto mt-3 mb-4 w-50"
                  alt="avatar"
                  src={item.img}
                  style={{
                    boxShadow:
                      '0 0 2px 0 rgba(0, 0, 0, .16), 0 1px 1px 0 rgba(0, 0, 0, .1)',
                  }}
                />
                <h5 className="text-capitalize">{item.name}</h5>
                <p className="fs-6 mb-0">{item.profile}</p>
                <div className="descContainer my-2">
                  <p className="truncate-overflow text-muted text-start mx-3">
                    <i className="fas fa-quote-left pe-3 fs-4 opacity-25"></i>
                    {item.desc.map((desc, i) => (
                      <span key={`testimonial-para${i}`}>
                        {desc}
                        {item.desc.length - 1 > i ? (
                          <>
                            <br /> <br />
                          </>
                        ) : null}
                      </span>
                    ))}
                  </p>
                  <Button
                    type="button"
                    className="my-blue-btn readmore"
                    onClick={(e) => {
                      if (
                        e.target.previousSibling.classList.contains('active')
                      ) {
                        e.target.innerHTML = 'Read more';
                      } else {
                        e.target.innerHTML = 'Read less';
                      }
                      e.target.previousSibling.classList.toggle('active');
                    }}
                  >
                    Read more
                  </Button>
                  {/* <ul className="list-unstyled d-flex justify-content-center text-warning ">
                  {Array.from(new Array(5)).map((_) => (
                    <li>
                      <i className="fas fa-star fa-sm"></i>
                    </li>
                  ))}
                </ul> */}
                </div>
              </Card>
            </div>
          ))}
        </Carousel>
      </Container>
    </Container>
  );
}

export default Testimonial;
