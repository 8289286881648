import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SectionHeading from '../../../ui/SectionHeading/SectionHeading';

import './StepProcess.css';

function StepProcess() {
  const dataColWidth = 6;
  return (
    <Container fluid="xl" className="stepProcess mt-4 py-4 my-blue-text">
      <SectionHeading type="h2">S.T.E.P Process</SectionHeading>
      <p className="w-75 mb-5 fs-6 mx-auto">
        Building, Growing and Protecting your wealth begins with iCreW's S.T.E.P
        or boarding process which help us to create a goal-driven strategy for
        every stage of your financial life.
      </p>

      {/* first section */}
      <Row className="align-items-center step-container d-flex">
        <Col
          xs={2}
          className="text-center bottom d-inline-flex justify-content-center align-items-center"
        >
          <div className="circle text-light">1</div>
        </Col>
        <Col xs={dataColWidth} className="hoverEffect py-3">
          <h5 className="s">SETUP</h5>
          <p>
            We start by understanding your financial priorities and goals,
            building a solid foundation for our strategy.
          </p>
        </Col>
      </Row>

      {/* path between 1-2 */}
      <Row className="timeline">
        <Col xs={2}>
          <div className="corner top-right"></div>
        </Col>
        <Col xs={8}>
          <hr />
        </Col>
        <Col xs={2}>
          <div className="corner left-bottom"></div>
        </Col>
      </Row>

      {/* second section */}
      <Row className="align-items-center justify-content-end step-container d-flex">
        <Col xs={dataColWidth} className="text-right hoverEffect py-3">
          <h5 className="t">TARGET</h5>
          <p>
            We collect and analyze your financial data to create a customized
            plan that aligns with your objectives, risk tolerance, and current
            situation.
          </p>
        </Col>
        <Col
          xs={2}
          className="text-center full d-inline-flex justify-content-center align-items-center"
        >
          <div className="circle text-light">2</div>
        </Col>
      </Row>

      {/* path between 2-3 */}
      <Row className="timeline">
        <Col xs={2}>
          <div className="corner right-bottom"></div>
        </Col>
        <Col xs={8}>
          <hr />
        </Col>
        <Col xs={2}>
          <div className="corner top-left"></div>
        </Col>
      </Row>

      {/* third section */}
      <Row className="align-items-center step-container d-flex">
        <Col
          xs={2}
          className="text-center full top-bottom d-inline-flex justify-content-center align-items-center"
        >
          <div className="circle text-light">3</div>
        </Col>
        <Col xs={dataColWidth} className="hoverEffect py-3">
          <h5 className="e">EXECUTE</h5>
          <p>
            We implement the tailored strategy and guide you through each
            detail, ensuring you feel confident and informed about your
            financial decisions.
          </p>
        </Col>
      </Row>

      {/* path between 3-4 */}
      <Row className="timeline">
        <Col xs={2}>
          <div className="corner top-right"></div>
        </Col>
        <Col xs={8}>
          <hr />
        </Col>
        <Col xs={2}>
          <div className="corner left-bottom"></div>
        </Col>
      </Row>

      {/* Fourth section */}
      <Row className="align-items-center justify-content-end step-container d-flex">
        <Col xs={dataColWidth} className="text-right hoverEffect py-3">
          <h5 className="p">PROGRESS</h5>
          <p>
            We continuously monitor and review your plan, making adjustments as
            needed and staying in touch to keep you on track toward your goals.
          </p>
        </Col>
        <Col
          xs={2}
          className="text-center top-only d-inline-flex justify-content-center align-items-center"
        >
          <div className="circle text-light">4</div>
        </Col>
      </Row>
    </Container>
  );
}

export default StepProcess;
