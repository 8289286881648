import { useState } from 'react';
import axios from 'axios';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Alert from 'react-bootstrap/Alert';

import './RequestAFreeAdvice.css';
import SectionHeading from '../../../ui/SectionHeading/SectionHeading';

function RequestAFreeAdvice() {
  const [validated, setValidated] = useState(false);
  const [response, setResponse] = useState();

  const handleSubmit = async (event) => {
    setValidated(true);
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity()) {
      var formData = Object.fromEntries(new FormData(form));

      try {
        const resp = await axios.post('/api/advice/submit', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        });

        if (resp.status === 200) {
          const url = window.URL.createObjectURL(resp.data);
          window.open(url);
        }
        setResponse(resp);
      } catch (err) {
        setResponse(err);
      }
    }
  };

  return (
    <Container fluid className="callback my-5 py-5">
      <Container fluid="xl" className="pt-5">
        <Row className="justify-content-center">
          <Col lg={7}>
            <div
              className="bg-white border rounded p-4 p-sm-5 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div
                className="text-center mx-auto my-blue-text wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ maxWidth: '600px' }}
              >
                <p className="fw-semi-bold mb-1">Get a Free Financial Advice</p>
                <SectionHeading type="h2">
                  Where are you on your journey?
                </SectionHeading>
              </div>
              <Form validated={validated} onSubmit={handleSubmit} noValidate>
                {response && (
                  <Alert
                    key={`${response.status === 200 ? 'success' : 'danger'}`}
                    variant={`${
                      response.status === 200 ? 'success' : 'danger'
                    }`}
                  >
                    {response.status === 200
                      ? 'Good job! Your financial plan generate successfully'
                      : response.message}
                  </Alert>
                )}

                {(response === undefined || response.status !== 200) && (
                  <Row className="g-3">
                    <Col sm={6}>
                      <FloatingLabel controlId="name" label="Your Name">
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Your Name"
                          required
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6}>
                      <FloatingLabel controlId="age" label="Your Age">
                        <Form.Control
                          type="number"
                          name="age"
                          placeholder="Your Age"
                          min="0"
                          required
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6}>
                      <FloatingLabel controlId="mail" label="Your Email">
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Your Email"
                          required
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6}>
                      <FloatingLabel controlId="mobile" label="Your Mobile">
                        <Form.Control
                          type="tel"
                          name="phone"
                          placeholder="Your Mobile"
                          required
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={12}>
                      <FloatingLabel
                        controlId="employmentStatus"
                        label="Employment Status"
                      >
                        <Form.Select
                          name="employmentStatus"
                          aria-label="Select Your Employment Status"
                        >
                          <option>Select Your Employment Status</option>
                          <option value="Salaried">Salaried</option>
                          <option value="Self Employed">Self Employed</option>
                          <option value="Business Owner">Business Owner</option>
                          <option value="Retired">Retired</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6}>
                      <FloatingLabel
                        controlId="formMonthlyIncome"
                        label="Monthly Income"
                      >
                        <Form.Control
                          type="number"
                          min="0"
                          name="monthlyincome"
                          placeholder="In-hand monthly salary"
                          required
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6}>
                      <FloatingLabel
                        controlId="formMonthlyExpenses"
                        label="Monthly Expenses"
                      >
                        <Form.Control
                          type="number"
                          min="0"
                          name="monthlyExpenses"
                          placeholder="Expenses per month"
                          required
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12}>
                      <FloatingLabel controlId="message" label="Message">
                        <Form.Control
                          as="textarea"
                          name="message"
                          placeholder="Leave a message here"
                          style={{ height: '100px' }}
                          required
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="text-center">
                      <Button className="py-2 px-4 my-blue-btn" type="submit">
                        Generate Your Free Financial Plan
                      </Button>
                    </Col>
                  </Row>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default RequestAFreeAdvice;
