import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Ratio from 'react-bootstrap/Ratio';

function FinancialJourney() {
  return (
    <Container fluid="xl" className="py-4 my-4">
      <Container>
        <Row>
          <Col
            lg={6}
            className="wow fadeInUp my-blue-text"
            data-wow-delay="0.1s"
          >
            <p className="fw-semi-bold mb-1">Empowering Every Indian's</p>
            <h2
              className="mb-4"
              style={{ color: 'var(--tertiary)', fontWeight: 600 }}
            >
              Financial Journey: World Class guidance for all
            </h2>
            <p className="mb-4 fs-6">
              We offer a one-step solutions for all your financial needs. By
              blending Financial Planning, Mutual Funds, Stocks Recommandation
              and Life Insurance into a tailored strategy, We help you reach
              your goals seamlessly.
            </p>
          </Col>
          <Col lg={6} className="wow fadeInUp" data-wow-delay="0.1s">
            <Ratio aspectRatio={'16x9'}>
              <iframe
                style={{
                  backgroundColor: '#cccccc54',
                }}
                className="rounded"
                src="https://www.youtube.com/embed/ASQNQTJ-37k?rel=0"
                title="Wealth Creation with icrew"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </Ratio>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default FinancialJourney;
