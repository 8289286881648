import AppointmentForm from '../../component/appointment/AppointmentForm/AppointmentForm';

function ScheduleAppointment() {
  return (
    <>
      <AppointmentForm />
    </>
  );
}

export default ScheduleAppointment;
