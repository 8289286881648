import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

function OurPeople() {
  return (
    <Container fluid="xl" className="mt-5 py-4">
      <Container>
        <Row>
          <Col
            lg={6}
            className="mb-lg-0 mb-4 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <Image className="w-100" src="assets/about/pic2.jpg" />
          </Col>
          <Col
            lg={6}
            className="wow fadeInUp my-blue-text"
            data-wow-delay="0.1s"
          >
            <p className="fw-semi-bold mb-1">Our People</p>
            <h2
              className="mb-4 text-capitalize"
              style={{ color: 'var(--tertiary)', fontWeight: 600 }}
            >
              Why People Choosing Us?
            </h2>
            <p className="mb-4 fs-6">
              At iCreW, our team of world class financial advisors brings
              experience from leading global banks to offer execptional
              guidance. We are commited to being accessible to all Indians,
              regardless of income level who need financial clarity. We focus on
              providing detailed financial plans without changing clients,
              ensuring that our expertise is available to those who seak it. Our
              vision is to empower India to create wealth.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default OurPeople;
