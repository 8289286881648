/* import Carousel from '../../component/home/Carousel/Carousel'; */
import Facts from '../../component/home/Facts/Facts';
import HowWeHelp from '../../component/home/HowWeHelp/HowWeHelp';
import QualifiedTeam from '../../component/home/QualifiedTeam/QualifiedTeam';
import RequestAFreeAdvice from '../../component/home/RequestAFreeAdvice/RequestAFreeAdvice';
import Testimonial from '../../component/home/Testimonial/Testimonial';
import VideoBanner from '../../component/home/VideoBanner/VideoBanner';

function Home() {
  return (
    <>
      {/* <Carousel  /> */}
      <VideoBanner />
      <HowWeHelp /> {/* animation */}
      <RequestAFreeAdvice /> {/* animation */}
      <QualifiedTeam />
      <Facts /> {/* animation */}
      <Testimonial /> {/* animation */}
    </>
  );
}

export default Home;
