import { ScrollRestoration } from 'react-router-dom';
import { Outlet /* , Link */ } from 'react-router-dom';
/* import { LinkContainer } from "react-router-bootstrap"; */
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const Layout = () => {
  return (
    <>
      {/* <nav>
        <ul>
          <li>
            <LinkContainer to="/">Home</LinkContainer>
          </li>
          <li>
            <Link to="/blogs">Blogs</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav> */}
      <Header />
      <ScrollRestoration />
      <div className="page">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Layout;
