import { useState } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Alert from 'react-bootstrap/Alert';

import './ContactUsForm.css';

function ContactUsForm() {
  const [validated, setValidated] = useState(false);
  const [response, setResponse] = useState();

  const handleSubmit = async (event) => {
    setValidated(true);
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity()) {
      var formData = Object.fromEntries(new FormData(form));

      try {
        const resp = await axios.post('/api/contact/submit', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setResponse(resp);
      } catch (err) {
        setResponse(err);
      }
    }
  };

  return (
    <Container fluid="xl" className="contactusform py-4">
      <Container className="contactus my-blue-text mx-auto">
        <p className="fw-semi-bold mb-1">Contact</p>
        <h2 style={{ fontWeight: 600 }}>
          If You Have Any Query, Please Contact Us
        </h2>
        <p className="mb-4 fs-6">
          Give us 5 minutes to listen to your situation, then we will connect
          you with an advisor ready to help you reach your financial goals.
        </p>

        <Row>
          <Col lg={6} className="wow fadeInUp" data-wow-delay="0.1s">
            <Form validated={validated} onSubmit={handleSubmit} noValidate>
              {response && (
                <Alert
                  key={`${response.status === 200 ? 'success' : 'danger'}`}
                  variant={`${response.status === 200 ? 'success' : 'danger'}`}
                >
                  {response.data.message}
                </Alert>
              )}

              {(response === undefined || response.status !== 200) && (
                <Row className="g-3">
                  <Col sm={12}>
                    <FloatingLabel controlId="name" label="Name">
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Name"
                        required
                      />
                    </FloatingLabel>
                  </Col>
                  <Col sm={12}>
                    <FloatingLabel controlId="mail" label="Email">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                      />
                    </FloatingLabel>
                  </Col>
                  <Col sm={12}>
                    <FloatingLabel controlId="mobile" label="Phone no">
                      <Form.Control
                        type="tel"
                        name="phone"
                        placeholder="Phone no"
                        required
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12}>
                    <FloatingLabel controlId="message" label="Comment">
                      <Form.Control
                        as="textarea"
                        name="comment"
                        placeholder="Leave a message here"
                        style={{ height: '100px' }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12}>
                    <Button className="py-2 px-4 my-blue-btn" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
          <Col
            lg={4}
            className="pt-4 mt-5 pt-lg-0 mt-lg-0 ms-lg-5 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <p className="fw-semi-bold mb-1">Free consultation</p>
            <h4 className="mb-4" style={{ fontWeight: 600 }}>
              Schedule a free consultation at your preferred time.
            </h4>
            <Button
              className="py-2 px-4 mb-4 my-blue-btn"
              as={Link}
              to="/appointment"
            >
              Schedule Appointment
            </Button>
            <p className="mb-4 fs-6">
              We offer a complimentary call to learn more about your goals.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default ContactUsForm;
