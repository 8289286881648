import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import SectionHeading from '../../../ui/SectionHeading/SectionHeading';

import './HowWeHelp.css';

function HowWeHelp() {
  const data = [
    {
      img: 'assets/service-1.jpg',
      title: 'financial planning',
    },
    {
      img: 'assets/service-2.jpg',
      title: 'mutual fund investment',
    },
    {
      img: 'assets/service-3.jpg',
      title: 'Term Insurance',
    },
    {
      img: 'assets/service-4.jpg',
      title: 'Stock Recommendation',
    },
  ];

  return (
    <Container fluid="xl" className="my-4 my-blue-text">
      <SectionHeading type="h2">How we help our clients</SectionHeading>
      <p className="mb-4 fs-6 text-center">
        At iCreW, your goals are our goals. Our advisors create a custom plan to
        fit your financial objectives.
      </p>

      <Row
        xs={2}
        md={2}
        lg={4}
        className="HowWeHelp g-4 my-3 fadeInUp align-items-stretch"
      >
        {data.map((_, idx) => (
          <Col key={idx}>
            <Card>
              <Card.Img variant="top" src={_.img} />
              <Card.Body className="d-flex align-items-center">
                <Card.Title className="text-capitalize mx-auto text-center">
                  {_.title}
                </Card.Title>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default HowWeHelp;
