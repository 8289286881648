const Feedbacks = [
  {
    id: 1,
    name: 'Abhijit Dash',
    img: 'assets/testimonial/Abhijit_Dash.jpg',
    profile: 'Program Manager, TCS',
    desc: [
      `iCreW completely turned around my finances! They helped me clean up my bad investments, set up mutual funds, suggested solid stocks, and got my life insurance sorted.`,
      `Now, I'm fully on track and confident in my financial future. Their expertise made all the difference. Highly recommend iCreW!`,
    ],
  },
  {
    id: 2,
    name: 'Gagan Jawa',
    img: 'assets/testimonial/Gagan_Jawa.jpg',
    profile: 'Senior Engineer at ticketmaster',
    desc: [
      `I wanted to express my sincere appreciation for the exceptional financial planning services you provided.`,
      `Your team's personalized approach and expertise in aligning my goals, like my children's education, retirement, and holiday savings, have been invaluable.`,
    ],
  },
  {
    id: 3,
    name: 'gururaj vijapur',
    img: 'assets/testimonial/gururaj_vijapur.jpg',
    profile: 'Delivery Leader, LTIMindtree',
    desc: [
      `iCreW stepped in and completely revamped my financial outlook. They fixed my poor investments, set up the right mutual funds, and gave spot-on stock recommendations.`,
      `They even sorted my life insurance, so now everything is in place. I finally feel secure, confident, and on track financially — thanks to iCreW!`,
    ],
  },
  {
    id: 4,
    name: 'Md Arif',
    img: 'assets/testimonial/Md_Arif.jpg',
    profile: 'Software Engineer at TietoEVRY',
    desc: [
      `Your team's dedication to understanding my unique aspirations, from my children's education to retirement and holiday dreams, has truly transformed my financial outlook.`,
      `Your expertise have not only provided me with a solid plan but also instilled confidence in my financial future.`,
    ],
  },
  {
    id: 5,
    name: 'Murari Kumar',
    img: 'assets/testimonial/Murari_Kumar.jpg',
    profile: 'Software Engineer 2 at PayPal',
    desc: [
      `I recently had the pleasure of working with iCreW for my financial planning, and I must say, the experience has been exceptional.`,
      `They not only helped me clean up my previous wrong investments but also set me up for success by configuring mutual funds
and suggesting the right stocks for my portfolio. Additionally, they ensured I had the appropriate life insurance in place, giving me peace of mind.`,
      `Thanks to their guidance,`,
      `I now feel totally on track with my financial goals.`,
      `I highly recommend iCreW to anyone looking to take control of their financial future!`,
    ],
  },
  {
    id: 6,
    name: 'Vibha S Narayan',
    img: 'assets/testimonial/Vibha_S_Narayan.jpg',
    profile: 'Manager at Publicis Sapient',
    desc: [
      `Working with iCreW has been a game-changer for my financial health!`,
      `They took a deep dive into my finances, cleaned up my past poor investments,
and set me on a much clearer path. With their expertise, I’ve now got a solid mutual fund portfolio,
smart stock suggestions, and the right life insurance coverage to protect my future.`,
      `Thanks to their tailored approach, I finally feel confident and in control of my finances.`,
      `Highly recommend iCreW to anyone who needs a thorough and reliable financial partner!
`,
    ],
  },
];

export default Feedbacks;
