import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import Button from 'react-bootstrap/Button';

function WhyiCreW() {
  return (
    <Container fluid="xl" className="py-4">
      <Container>
        <Row>
          <Col
            lg={6}
            className="wow fadeInUp my-blue-text"
            data-wow-delay="0.1s"
          >
            <p className="fw-semi-bold mb-1">About us</p>
            <h2 className="mb-4" style={{ fontWeight: 600 }}>
              Why iCreW?
            </h2>
            <p className="mb-4 fs-6">
              Our mission is to make managing your finance easier. We work with
              high-net-worth familes & business owners to establish clear goals,
              then develop strategies that help them reach those goals through
              careful planning and tranparent communication.
            </p>
            <Button className="py-2 px-4 my-blue-btn">Get Started</Button>
          </Col>
          <Col
            lg={6}
            className="wow mt-lg-0 mt-4 fadeInUp"
            data-wow-delay="0.1s"
          >
            <Image className="w-100" src="assets/about/pic1.jpg" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default WhyiCreW;
