function SectionHeading({ children, ...props }) {
  const { type = 'h1' } = props;

  if (type === 'h2') {
    return <h2 className="mb-4 text-capitalize text-center">{children}</h2>;
  }

  return (
    <h1
      className="display-5 mb-4 text-capitalize"
      style={{
        fontWeight: 700,
        color: 'var(--tertiary)',
      }}
    >
      {children}
    </h1>
  );
}

export default SectionHeading;
