import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import TopHeader from '../Header/TopHeader/TopHeader';
import SocialLink from '../../data/SocialLink';

import iCreW_Logo from '../../img/iCreW_Logo.png';

import './Header.css';

function Header() {
  const fixedTopEle = useRef(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    function handleScroll() {
      // set shadow on scroll
      let winWidth = Math.ceil(
        window.document.body.getBoundingClientRect().width
      );
      let scrollTop = window.scrollY;

      if (scrollTop > 45) {
        fixedTopEle.current.classList.add('shadow');
        if (winWidth > 992) {
          fixedTopEle.current.style.top = '-45px';
        }
      } else {
        fixedTopEle.current.classList.remove('shadow');
        fixedTopEle.current.style.top = '0px';
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function onMobileClose() {
    setExpanded(false);
  }

  return (
    <Container fluid className="header fixed-top px-0" ref={fixedTopEle}>
      <TopHeader />

      <Navbar
        expand="lg"
        className="py-lg-0 px-lg-5"
        bg="light"
        data-bs-theme="light"
        expanded={expanded}
        /* collapseOnSelect={true}
        onSelect={(e) => {
          console.log('e:', e);
        }} */
      >
        <Navbar.Brand
          as={Link}
          to="/"
          onClick={onMobileClose}
          className="ms-4 py-0 ms-lg-0"
        >
          <Image src={iCreW_Logo} />
        </Navbar.Brand>

        <Navbar.Toggle
          className="me-4"
          aria-controls="navbarCollapse"
          onClick={() => setExpanded(!expanded)}
        >
          <i className="fa fa-bars"></i>
        </Navbar.Toggle>

        <Navbar.Collapse id="navbarCollapse">
          <Nav className="ms-auto p-4 p-lg-0">
            <Nav.Item>
              <Nav.Link as={Link} to="/about" onClick={onMobileClose}>
                About
              </Nav.Link>
            </Nav.Item>
            <NavDropdown title="What We Do" className="border-light m-0">
              <NavDropdown.Item href="#">Investing</NavDropdown.Item>
              <NavDropdown.Item href="#">Stocks & Mutual Fund</NavDropdown.Item>
              <NavDropdown.Item href="#">Financial Planning</NavDropdown.Item>
              <NavDropdown.Item href="#">Retirement Planning</NavDropdown.Item>
            </NavDropdown>
            <Nav.Item>
              <Nav.Link href="#" onClick={onMobileClose}>
                Testimonials
              </Nav.Link>
            </Nav.Item>
            <NavDropdown title="News & Resources" className="border-light m-0">
              <NavDropdown.Item href="#">Blog</NavDropdown.Item>
              <NavDropdown.Item href="#">Videos</NavDropdown.Item>
              <NavDropdown.Item href="#">
                Planning & Investment Guides
              </NavDropdown.Item>
              <NavDropdown.Item href="#">Financial Dictionary</NavDropdown.Item>
              <NavDropdown.Item href="#">FAQs</NavDropdown.Item>
            </NavDropdown>
            <Nav.Item>
              <Nav.Link as={Link} to="/contact" onClick={onMobileClose}>
                Contact
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="social d-none d-lg-flex ms-2">
            {SocialLink.map((link, i) => (
              <Button
                variant="light"
                className="rounded-circle btn-sm-square ms-3"
                href={link.url}
                target="_blank"
                key={`headerSocialIcon${i}`}
              >
                <small
                  className={`fa-brands ${link.icon}`}
                  style={{ color: link.color, opacity: 0.7 }}
                ></small>
              </Button>
            ))}
          </div>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}

export default Header;
